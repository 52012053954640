import { getGaId } from '@front/shared/utils';
import { Jurisdiction } from '@shared/master-types';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import { ReactComponent as EarthIcon } from '../../../../public/icons/earth.svg';
import { DropdownMenu } from '../DropdownMenu';

type TJurisdictionGroupButtonProps = {
  jurisdictionList: Jurisdiction[];
  jurisdictionItem: Jurisdiction;
  currentLocale?: string;
};

const JurisdictionGroupButton: React.FC<
  TJurisdictionGroupButtonProps
> = props => {
  const router = useRouter();
  const { jurisdictionList, jurisdictionItem, currentLocale } = props;

  return (
    <div className='relative'>
      <DropdownMenu
        label={jurisdictionItem.label}
        Icon={EarthIcon}
        isOpenOnClick
        isJurisdictionButton
        dataGaId={getGaId(jurisdictionItem.label, 'jurisdictions')}
      >
        {jurisdictionList
          .filter(jurisdiction => jurisdiction.slug !== jurisdictionItem.slug)
          .map((jurisdiction, index) => {
            const path = router.asPath;

            const isReplace =
              path === `/${currentLocale}` ||
              path.startsWith(`/${currentLocale}/`);

            const newPath =
              currentLocale && isReplace
                ? path.replace(`/${currentLocale}`, '')
                : path;

            const isNewPathReplace =
              newPath === `/${jurisdictionItem.slug}` ||
              newPath.startsWith(`/${jurisdictionItem.slug}/`);

            const newPathReplaced = isNewPathReplace
              ? newPath.replace(jurisdictionItem.slug, jurisdiction.slug)
              : `/${jurisdiction.slug + newPath}`;

            const href = `/${currentLocale + newPathReplaced}`;

            return (
              <Link
                key={index}
                href={href}
                className='block rounded-lg px-3 py-2 text-sm font-medium leading-normal text-control-950 transition hover:bg-control-100 aria-disabled:cursor-not-allowed aria-disabled:opacity-40'
                data-ga-id={getGaId(jurisdiction.label, 'jurisdiction')}
              >
                <div className='gap-4px flex flex-col justify-start'>
                  <span>{jurisdiction.label}</span>

                  <span className='text-xs font-normal text-control-600'>
                    {jurisdiction.description}
                  </span>
                </div>
              </Link>
            );
          })}
      </DropdownMenu>
    </div>
  );
};

export default JurisdictionGroupButton;
