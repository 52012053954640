import { checkLocaleExist, getGaId } from '@front/shared/utils';
import { Jurisdiction, Locale } from '@shared/master-types';
import clsx from 'clsx';
import { DEFAULT_JURISDICTION, DEFAULT_LOCALE } from 'front/shared/consts';
import Image, { ImageProps } from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ReactComponent as IconArrowUp } from '../../../../public/icons/arrow-black.svg';
import { ReactComponent as IconCross } from '../../../../public/icons/icon-cross.svg';
import { ReactComponent as IconMenuClosed } from '../../../../public/icons/icon-menu-closed.svg';
import { EActionType, TAction } from '../../../types';
import {
  Button,
  EButtonSize,
  EButtonTheme,
  EButtonVariant,
} from '../../atoms/Button';
import { DropdownMenu } from '../../atoms/DropdownMenu';
import { JurisdictionGroupButton } from '../../atoms/JurisdictionGroupButton';
import { ScrollIndicator } from '../../atoms/ScrollIndicator';
import { Option, Select, ESelectVariant } from '../../atoms/Select';
import { Container } from '../../sections/Container';

export interface IHeaderProps {
  logo: ImageProps | null;
  menuItems: TAction[];
  actions?: TAction[];
  locales?: Locale[];
  jurisdictionList?: Jurisdiction[];
  jurisdictionItem?: Jurisdiction;
}

const Header: React.FC<IHeaderProps> = props => {
  const {
    logo,
    locales = [],
    menuItems = [],
    actions = [],
    jurisdictionList,
    jurisdictionItem,
  } = props;

  const router = useRouter();

  const pathPatern = useRouter().asPath.split('/')[1];

  const isLocale = checkLocaleExist({
    locale: pathPatern,
    localeList: locales,
  });
  const currentLocale = isLocale ? pathPatern : DEFAULT_LOCALE;

  const localeList = locales.map(l => ({
    value: l.locale,
    label: l.label,
    country: l.country,
  }));

  const [isPageScrolled, setIsPageScrolled] = useState(false);

  const dialogRef = useRef<HTMLDialogElement>(null);

  const currentLocaleIndex =
    localeList.findIndex(s => s.value === currentLocale) || 0;

  const closeModal = (): void => {
    if (dialogRef.current && dialogRef.current.open) {
      dialogRef.current.close();
    }
  };

  const homeUrlParts = [];
  if (router.query.locale && router.query.locale !== DEFAULT_LOCALE) {
    homeUrlParts.push(String(router.query.locale));
  }
  if (
    router.query.jurisdiction &&
    router.query.jurisdiction !== DEFAULT_JURISDICTION
  ) {
    homeUrlParts.push(String(router.query.jurisdiction));
  }
  const homeUrl = `/${homeUrlParts.join('/')}`;

  const Options = useMemo(
    () =>
      localeList.map((c, i) => (
        <Option hideSelected={true} key={i} label={c.label}>
          <svg
            height='14'
            width='14'
            className='h-[0.6875rem] w-3.5 shrink-0 overflow-hidden rounded-[0.1875rem] border-[0.9px] border-surface-200 object-cover object-center'
          >
            <use xlinkHref={`/icons/flags/flags-sprite.svg#${c.country}`}></use>
          </svg>
          <span className='whitespace-nowrap'>{c.label}</span>
        </Option>
      )),
    [localeList],
  );

  const CurrentFlag = useMemo(
    () =>
      localeList[currentLocaleIndex] && (
        <Image
          width={14}
          height={14}
          src={`/icons/flags/${localeList[currentLocaleIndex].country}.svg`}
          className='h-[0.6875rem] w-3.5 overflow-hidden rounded-[0.1875rem] object-cover object-center'
          alt={localeList[currentLocaleIndex].country}
        />
      ),
    [currentLocaleIndex, localeList],
  );

  const handleDialogControlClick = useCallback((): void => {
    if (dialogRef.current) {
      return dialogRef.current.open
        ? dialogRef.current.close()
        : dialogRef.current.showModal();
    }
  }, []);

  const handleLocaleChange = useCallback(
    (localeIndex: number | null) => {
      if (localeIndex !== null) {
        closeModal();

        const nextLocale = localeList[localeIndex].value;

        if (nextLocale) {
          const newAsPath =
            router.asPath.startsWith(`/${currentLocale}/`) ||
            router.asPath === `/${currentLocale}`
              ? router.asPath.replace(currentLocale, nextLocale)
              : '/' + nextLocale + router.asPath;

          if (currentLocale !== nextLocale) {
            void router.push(newAsPath);
          }
        }
      }
    },
    [localeList, router, currentLocale],
  );

  const handleDialogClick = (
    e: React.MouseEvent<HTMLDialogElement, MouseEvent>,
  ): void => {
    if (e.target === dialogRef.current) {
      dialogRef.current.close();
    }
  };

  const renderActionByType = function handleActionType({
    action,
    index,
    isMenuAction = false,
  }: {
    action: TAction;
    index: number;
    isMenuAction?: boolean;
  }): React.JSX.Element {
    switch (action.type) {
      case EActionType.LinksGroupType:
        return (
          <DropdownMenu
            label={action.label || ''}
            dataGaId={getGaId(action.dataGaId || action.label, 'header')}
            Icon={IconArrowUp}
          >
            {action.actions?.map((actionItem, i) => {
              return handleActionType({
                action: actionItem,
                index: i,
                isMenuAction,
              });
            })}
          </DropdownMenu>
        );

      case EActionType.ButtonType:
        return (
          <Button
            key={index}
            {...action.props}
            variant={EButtonVariant.Contained}
            theme={EButtonTheme.Primary}
            size={EButtonSize.Float}
            data-ga-id={getGaId(action.dataGaId || action.text, 'header')}
          />
        );
      case EActionType.LinkType:
      default:
        return (
          <Button
            key={index}
            {...action.props}
            variant={EButtonVariant.Text}
            data-ga-id={getGaId(action.dataGaId || action.text, 'header')}
            className={clsx({
              'h-auto justify-start rounded-lg px-3 py-2 text-sm font-medium leading-normal text-control-950 outline-none transition hover:bg-control-100 hover:opacity-100 aria-disabled:cursor-not-allowed aria-disabled:opacity-40':
                !isMenuAction,
            })}
          />
        );
    }
  };

  useEffect(() => {
    const handler = (): void => {
      setIsPageScrolled(window.scrollY > 10);
    };

    const handleRouteChangeComplete = (): void => {
      closeModal();
    };

    window.addEventListener('scroll', handler, { passive: true });
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      window.removeEventListener('scroll', handler);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router]);

  return (
    <section className='sticky top-0 z-50 max-w-[100vw] py-2 2xl:py-3'>
      <Container isPadding={false}>
        <nav
          className={clsx(
            'relative mx-2 flex items-center justify-between rounded-xl px-2 py-2 backdrop-blur-md transition 2xl:-mx-2 2xl:rounded-3xl 2xl:px-6 2xl:py-5',
            isPageScrolled && 'bg-interface-500 bg-opacity-20',
          )}
        >
          {logo && (
            // TODO: prevent link to current path (all links)
            <Link href={homeUrl} data-ga-id={getGaId('logo', 'header')}>
              <Image {...logo} className='h-6 w-auto 2xl:h-8' priority />
            </Link>
          )}
          <div className='hidden items-center gap-8 2xl:flex'>
            {menuItems.length !== 0 && (
              <ul className='flex flex-wrap items-center gap-1 self-center'>
                {menuItems.map((menuItem, index) => (
                  <li key={index}>
                    {renderActionByType({ action: menuItem, index })}
                  </li>
                ))}
                {localeList.length > 1 && (
                  <li>
                    <Select
                      variant={ESelectVariant.Menu}
                      value={currentLocaleIndex}
                      options={localeList}
                      icon={CurrentFlag}
                      onChange={handleLocaleChange}
                    >
                      {Options}
                    </Select>
                  </li>
                )}
              </ul>
            )}
            {jurisdictionList && jurisdictionItem && (
              <JurisdictionGroupButton
                jurisdictionList={jurisdictionList}
                jurisdictionItem={jurisdictionItem}
                currentLocale={currentLocale}
              />
            )}
            {actions && (
              <div className='flex items-center gap-5'>
                {actions.map((action, index) =>
                  renderActionByType({ action, index, isMenuAction: true }),
                )}
              </div>
            )}
          </div>
          <div className='flex items-center gap-3 2xl:hidden'>
            {jurisdictionList && jurisdictionItem && (
              <JurisdictionGroupButton
                jurisdictionList={jurisdictionList}
                jurisdictionItem={jurisdictionItem}
                currentLocale={currentLocale}
              />
            )}
            <button
              className='flex h-9 w-9 items-center justify-center rounded-xl bg-control-100'
              onClick={handleDialogControlClick}
            >
              <IconMenuClosed className='aspect-square h-5 w-5 text-control-950' />
            </button>
          </div>

          <dialog
            ref={dialogRef}
            className='fixed inset-x-0 bottom-0 p-0 backdrop:bg-interface-1000 backdrop:bg-opacity-70 2xl:hidden'
            onClick={handleDialogClick}
          >
            <form
              method='dialog'
              className='fixed left-0 right-0 top-full animate-translate-up rounded-[1.75rem_1.75rem_0_0] bg-surface-50 px-4 pb-8 pt-6'
            >
              <div className='flex justify-between'>
                {logo && (
                  <Link href='/' className='outline-none'>
                    <Image {...logo} className='h-6 w-auto' />
                  </Link>
                )}
                <button formMethod='dialog' className='relative end-4'>
                  <IconCross className='aspect-square h-4 w-4 text-control-600' />
                </button>
              </div>
              {menuItems.length !== 0 && (
                <nav>
                  <ul className='mt-6 flex flex-col gap-3'>
                    {localeList.length > 1 && (
                      <li className='relative'>
                        <Select
                          variant={ESelectVariant.Menu}
                          value={currentLocaleIndex}
                          options={localeList}
                          icon={CurrentFlag}
                          onChange={handleLocaleChange}
                        >
                          {Options}
                        </Select>
                      </li>
                    )}
                    {menuItems.map((menuItem, index) => (
                      <li key={index}>
                        {menuItem.type === EActionType.LinkType && (
                          <Link
                            {...menuItem.props}
                            onClick={handleDialogControlClick}
                            className='block rounded px-3 py-2 text-sm font-medium leading-normal text-control-950 transition hover:bg-control-100'
                          />
                        )}
                        {menuItem.type === EActionType.LinksGroupType && (
                          <Select
                            variant={ESelectVariant.Menu}
                            value={null}
                            label={menuItem.label}
                            options={[]}
                            onChange={() => null}
                          >
                            {menuItem.actions?.map((actionItem, i) => {
                              return renderActionByType({
                                action: actionItem,
                                index: i,
                              });
                            })}
                          </Select>
                        )}
                      </li>
                    ))}
                  </ul>
                </nav>
              )}
              {actions && (
                <div className='mt-6 flex flex-col gap-6 prose-a:py-0 prose-a:text-center prose-a:text-brand-500'>
                  {actions.map((action, index) =>
                    renderActionByType({ action, index, isMenuAction: true }),
                  )}
                </div>
              )}
            </form>
          </dialog>
          <ScrollIndicator />
        </nav>
      </Container>
    </section>
  );
};

export default Header;
