import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingList,
  FloatingNode,
  FloatingPortal,
  offset,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useHover,
  useInteractions,
  useListItem,
  useListNavigation,
  useMergeRefs,
  useRole,
} from '@floating-ui/react';
import clsx from 'clsx';
import {
  forwardRef,
  useContext,
  useRef,
  useState,
  createContext,
  HTMLProps,
  Dispatch,
  SetStateAction,
  ReactNode,
  useEffect,
  FocusEvent,
  ButtonHTMLAttributes,
  MouseEvent,
  FunctionComponent,
  SVGProps,
} from 'react';

import { ReactComponent as IconArrowUp } from '../../../../public/icons/arrow-up-no-frame.svg';

const MenuContext = createContext<{
  getItemProps: (userProps?: HTMLProps<HTMLElement>) => Record<string, unknown>;
  activeIndex: number | null;
  setActiveIndex: Dispatch<SetStateAction<number | null>>;
  setHasFocusInside: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}>({
  getItemProps: () => ({}),
  activeIndex: null,
  setActiveIndex: () => null,
  setHasFocusInside: () => null,
  isOpen: true,
});

interface IMenuProps {
  label: string;
  nested?: boolean;
  isOpenOnClick?: boolean;
  isJurisdictionButton?: boolean;
  children?: ReactNode;
  Icon?: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  dataGaId?: string;
}

const OPEN_DELAY_MS = 75;

type TMenuComponentProps = IMenuProps &
  HTMLProps<HTMLButtonElement> & {
    labelClassName?: string;
    dropdownClassName?: string;
  };

export const MenuComponent = forwardRef<HTMLButtonElement, TMenuComponentProps>(
  (
    {
      children,
      label,
      isOpenOnClick = false,
      isJurisdictionButton = false,
      Icon,
      dataGaId,
      className,
      labelClassName,
      dropdownClassName,
      ...props
    },
    forwardedRef,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [hasFocusInside, setHasFocusInside] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const elementsRef = useRef<Array<HTMLButtonElement | null>>([]);
    const labelsRef = useRef<Array<string | null>>([]);
    const parent = useContext(MenuContext);

    const tree = useFloatingTree();
    const nodeId = useFloatingNodeId();
    const parentId = useFloatingParentNodeId();
    const item = useListItem();

    const { floatingStyles, refs, context } = useFloating<HTMLButtonElement>({
      nodeId,
      open: !isOpenOnClick ? isOpen : undefined,
      onOpenChange: !isOpenOnClick ? setIsOpen : undefined,
      placement: 'bottom-start',
      middleware: [offset({ mainAxis: 4, alignmentAxis: 0 }), flip(), shift()],
      whileElementsMounted: autoUpdate,
      strategy: 'fixed',
    });

    const hover = useHover(context, {
      delay: { open: OPEN_DELAY_MS },
      handleClose: safePolygon({ blockPointerEvents: true }),
    });
    const click = useClick(context, {
      event: 'mousedown',
      toggle: isOpen,
    });
    const role = useRole(context, { role: 'menu' });
    const dismiss = useDismiss(context, { bubbles: true });
    const listNavigation = useListNavigation(context, {
      listRef: elementsRef,
      activeIndex,
      onNavigate: setActiveIndex,
    });

    const { getReferenceProps, getFloatingProps, getItemProps } =
      useInteractions([hover, click, role, dismiss, listNavigation]);

    useEffect(() => {
      if (isOpen && tree) {
        tree.events.emit('menuopen', { parentId, nodeId });
      }
    }, [tree, isOpen, nodeId, parentId, isOpenOnClick]);

    useEffect(() => {
      if (!isOpenOnClick) return;

      const closeDropdown = (event: Event): void => {
        const node = document.querySelector('button[data-open]');
        const floatingMenu = document.querySelector('div[role="menu"]');
        if (!node || !floatingMenu) return;

        const targetInNode =
          node.contains(event.target as Node) ||
          floatingMenu.contains(event.target as Node);
        const targetIsNode =
          node === event.target || floatingMenu === event.target;
        if (targetInNode || targetIsNode) return;

        setIsOpen(false);
      };

      window.addEventListener('click', closeDropdown);

      return () => {
        window.removeEventListener('click', closeDropdown);
      };
    }, [isOpenOnClick, label]);

    return (
      <FloatingNode id={nodeId}>
        <button
          ref={useMergeRefs([refs.setReference, item.ref, forwardedRef])}
          data-open={isOpen ? '' : undefined}
          data-focus-inside={hasFocusInside ? '' : undefined}
          data-ga-id={dataGaId}
          className={clsx(
            'relative flex h-9 items-center gap-1 rounded-xl px-3 text-sm font-medium leading-normal text-control-950 hover:bg-control-100 hover:text-control-1000',
            {
              'bg-control-100 px-8 text-control-950 hover:bg-control-150 hover:text-control-1000':
                isJurisdictionButton,
            },
            className,
          )}
          {...getReferenceProps(
            parent.getItemProps({
              ...props,
              onFocus(event: FocusEvent<HTMLButtonElement>) {
                props.onFocus?.(event);
                setHasFocusInside(false);
                parent.setHasFocusInside(true);
              },
            }),
          )}
          onClick={() => {
            if (!isOpenOnClick) return;
            setIsOpen(!isOpen);
          }}
        >
          <span className={labelClassName}>{label}</span>
          {Icon ? (
            <Icon width={10} height={10} />
          ) : (
            <IconArrowUp
              width={10}
              height={6}
              className={clsx(
                'transition',
                !isOpen && 'translate-y-[2px] rotate-180',
              )}
            />
          )}
        </button>
        <MenuContext.Provider
          value={{
            activeIndex,
            setActiveIndex,
            getItemProps,
            setHasFocusInside,
            isOpen,
          }}
        >
          <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
            {isOpen && (
              <FloatingPortal>
                <FloatingFocusManager context={context} modal={false}>
                  <div
                    onClick={() => {
                      if (!isOpenOnClick) return;
                      setIsOpen(!isOpen);
                    }}
                    ref={refs.setFloating}
                    className={clsx(
                      'z-50 box-border flex w-max flex-col overflow-y-auto rounded-xl bg-surface-50-dropdown p-2 text-sm font-medium leading-normal text-control-950 shadow-md',
                      dropdownClassName,
                    )}
                    style={floatingStyles}
                    {...getFloatingProps()}
                  >
                    {children}
                  </div>
                </FloatingFocusManager>
              </FloatingPortal>
            )}
          </FloatingList>
        </MenuContext.Provider>
      </FloatingNode>
    );
  },
);

interface IMenuItemProps {
  label: string;
  disabled?: boolean;
}

export const MenuItem = forwardRef<
  HTMLButtonElement,
  IMenuItemProps & ButtonHTMLAttributes<HTMLButtonElement>
>(({ label, disabled, ...props }, forwardedRef) => {
  const menu = useContext(MenuContext);
  const item = useListItem({ label: disabled ? null : label });
  const tree = useFloatingTree();
  const isActive = item.index === menu.activeIndex;

  return (
    <button
      {...props}
      ref={useMergeRefs([item.ref, forwardedRef])}
      type='button'
      role='menuitem'
      className='MenuItem'
      tabIndex={isActive ? 0 : -1}
      disabled={disabled}
      {...menu.getItemProps({
        onClick(event: MouseEvent<HTMLButtonElement>) {
          props.onClick?.(event);
          tree?.events.emit('click');
        },
        onFocus(event: FocusEvent<HTMLButtonElement>) {
          props.onFocus?.(event);
          menu.setHasFocusInside(true);
        },
      })}
    >
      {label}
    </button>
  );
});

const DropdownMenu = forwardRef<HTMLButtonElement, TMenuComponentProps>(
  (props, ref) => {
    return <MenuComponent {...props} ref={ref} />;
  },
);

export default DropdownMenu;
